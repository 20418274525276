// extracted by mini-css-extract-plugin
export var author = "style-module--author--42d31";
export var blink = "style-module--blink--746c9";
export var buttonCancel = "style-module--buttonCancel--10a4a";
export var buttonConfirm = "style-module--buttonConfirm--7ab96";
export var buttonContainer = "style-module--buttonContainer--d01c0";
export var buttonRow = "style-module--buttonRow--53832";
export var content = "style-module--content--cd158";
export var contentHeader = "style-module--contentHeader--f50a6";
export var conversationContainer = "style-module--conversationContainer--b8d07";
export var listingTitle = "style-module--listingTitle--f8391";
export var messageBody = "style-module--messageBody--d5586";
export var messageBubble = "style-module--messageBubble--00b0e";
export var messageBubbleContainer = "style-module--messageBubbleContainer--cff22";
export var messageContent = "style-module--messageContent--166a6";
export var messageList = "style-module--messageList--242d0";
export var messageListingName = "style-module--messageListingName--673ee";
export var messageListingTitle = "style-module--messageListingTitle--96523";
export var messagePreview = "style-module--messagePreview--b16a4";
export var messageStatus = "style-module--messageStatus--6951f";
export var messageTab = "style-module--messageTab--f0fab";
export var pageLink = "style-module--pageLink--d563d";
export var pageList = "style-module--pageList--b696d";
export var pageWrapper = "style-module--pageWrapper--4974c";
export var read = "style-module--read--da16e";
export var reply = "style-module--reply--7f417";
export var requestDate = "style-module--requestDate--a6956";
export var reservation = "style-module--reservation--47462";
export var sent = "style-module--sent--a2da7";
export var status = "style-module--status--08853";
export var tag = "style-module--tag--8a79f";
export var tagList = "style-module--tagList--f8e49";
export var textarea = "style-module--textarea--68728";
export var typing = "style-module--typing--bf4c3";
export var unread = "style-module--unread--40afc";