import 'stream-chat-react/dist/css/v2/index.css';
import React from 'react';
import type { ChannelFilters, ChannelOptions, ChannelSort } from 'stream-chat';
import { ChannelList } from 'stream-chat-react';

type MessagesProps = {
  channelListOptions: {
    options: ChannelOptions;
    filters: ChannelFilters;
    sort: ChannelSort;
  };
};

const Messages = (props: MessagesProps) => {
  const { channelListOptions } = props;

  return (
    <ChannelList
      {...channelListOptions}
      // Preview={(props) => <MessagingChannelPreview {...props} />}
    />
  );
};

export default Messages;
