import Chat from '../Chat'
import TemplateFullWidth from '../TemplateFullWidth'
import * as style from './style.module.scss'
import Link from 'gatsby-link'
import React from 'react'

const PageMessage = () => (
  <TemplateFullWidth>
    <div className={style.pageWrapper}>
      <Chat
        apiKey='vt26ddprnwsx'
        // userToConnect={{ id: 'doggoscom-inc' }}
        // userToken='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiZG9nZ29zY29tLWluYyJ9.OcRIhH44UkT27SOj5R49BNU2YqhJkaNBMVvhFJ8wPVk'
        // channel='amccloud'
        userToConnect={{ id: 'amccloud' }}
        userToken='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiYW1jY2xvdWQifQ.Dk8OoXsU6cnlwfJDhDmyUjKnt4apxMtPRz44LFkjc8k'
        channel='doggoscom-inc'
      />
    </div>
    {/*     
    <div className={style.pageWrapper}>
      <Sidebar />
      <div className={style.content}>
        <div className={style.contentHeader}>
          <p className={style.status}>Pending</p>
          <h1 className={style.listingTitle}>Vacation at the Dog Castle</h1>
          <p className={style.requestDate}>Sep 4, 2023 - Sep 9, 2023</p>
          <div className={style.tagList}>
            <div className={style.tag}>Bossy</div>
            <div className={style.tag}>Aurelieus</div>
          </div>
        </div>
        <div className={style.conversationContainer}>
          <div className={`${style.messageBubbleContainer} ${style.sent}`}>
            <div className={style.messageBubble}>
              Hello! I’m looking for a sitter for my two German Shepherd’s,
              Bossy and Aurelieus. They both get along with eachother super
              well, but they must stay together because they get terrible
              separation anxiety if they’re away from eachother for more than
              like... 5 seconds... lol! 😅 Do you guys have any availability for
              my dates?
            </div>
            <div className={style.author}>
              <strong>You</strong>
            </div>
          </div>
          <div className={`${style.messageBubbleContainer} ${style.reply}`}>
            <div className={style.messageBubble}>
              Hello Jacqueline! We would LOVE to host Bossy and Aurelieus! Let’s
              schedule a meeting before your reservation. Are there any
              day/times that work for you?
            </div>
            <div className={style.author}>
              <strong>Gabriel</strong> from{' '}
              <a href='#'>Vacation at the Dog Castle</a>
            </div>
          </div>
          <div className={`${style.messageBubbleContainer} ${style.sent}`}>
            <div className={style.messageBubble}>
              Great meeting you! Let’s lock-in the reservation?
            </div>
            <div className={style.author}>
              <strong>You</strong>
            </div>
          </div>
          <div className={`${style.messageBubbleContainer} ${style.reply}`}>
            <div className={style.messageBubble}>you got it!</div>
            <div className={style.author}>
              <strong>Gabriel</strong> from{' '}
              <a href='#'>Vacation at the Dog Castle</a>
            </div>
          </div>
          <div className={`${style.messageBubbleContainer} ${style.typing}`}>
            <div className={style.messageBubble}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className={style.author}>
              <strong>Gabriel</strong> from{' '}
              <a href='#'>Vacation at the Dog Castle</a>
            </div>
          </div>
          <div
            className={`${style.messageBubbleContainer} ${style.reservation}`}
          >
            <div className={style.messageBubble}>
              <div>
                Hello Jacqueline! Good news, we would LOVE to accept your
                reservation! Please “confirm” this booking to proceed with your
                reservation, or “deny” to archive it.
              </div>
              <div className={style.buttonContainer}>
                <div className={style.buttonCancel}>Deny booking</div>
                <div className={style.buttonConfirm}>Confirm booking</div>
              </div>
            </div>
            <div className={style.author}>
              <strong>Gabriel</strong> from{' '}
              <a href='#'>Vacation at the Dog Castle</a>
            </div>
          </div>
        </div>
        <div className={style.textarea}>
          <textarea rows='4'></textarea>
          <div className={style.buttonRow}>
            <div>
              <button>Emoji picker :)</button>
              <button>Upload image or video</button>
            </div>
            <button>Send message</button>
          </div>
        </div>
      </div>
    </div> */}
  </TemplateFullWidth>
)

export default PageMessage
